body {
  font-family: 'Nunito';
  margin: 0;
}

code {
  font-family: 'Nunito';
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Regular.ttf") format("truetype");
}